.disabilities-modal .disabilities-modal-body {
  padding: var(--spacing-lg);
}
.disabilities-modal .disabilities-modal-header {
  padding-left: var(--spacing-md);
  padding-right: var(--spacing-md);
}
.disabilities-modal .disabilities-modal-button {
  width: 100%;
}
