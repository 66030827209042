.housekeeping-dashboard-body.dashboard-mode-selection {
  border: var(--selection-mode-border-width) solid var(--color-primary-action);
}
.housekeeping-dashboard-body .housekeeping-dashboard-press {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.housekeeping-dashboard-body .housekeeping-dashboard-list {
  flex: 1 1 100%;
}
