.chips {
  max-width: 100%;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  overflow-y: hidden;
}
.chips .chips-scroll {
  overflow: scroll hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
}
.chips .chips-scroll::-webkit-scrollbar {
  display: none;
}
