.sheets-summary-accordion.sheets-summary-accordion2 {
  display: grid;
  grid-template-columns: 64px minmax(0, 1fr);
  padding: var(--spacing-md);
  border-radius: 8px;
  background-color: var(--color-white);
}
.sheets-summary-accordion.sheets-summary-accordion2 .sheets-summary-title-content-divider {
  margin-bottom: var(--spacing-sm);
}
.sheets-summary-accordion.sheets-summary-accordion2 .ac-accordion-collapsed .sheets-summary-title-content-divider {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s;
}
.sheets-summary-accordion.sheets-summary-accordion2 .ac-accordion-expanded .sheets-summary-title-content-divider {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s;
}
.sheets-summary-accordion.sheets-summary-accordion2 .ac-accordion,
.sheets-summary-accordion.sheets-summary-accordion2 .ac-accordion-item {
  padding: 0;
  border: 0;
}
.sheets-summary-accordion.sheets-summary-accordion2 .sheets-summary-accordion-info {
  padding-left: var(--spacing-md);
}
.sheets-summary-accordion.sheets-summary-accordion2 .sheets-summary-accordion-icon {
  padding-right: var(--spacing-md);
  border-right: 1px solid var(--color-gray200);
}
