.housekeeping-details-accompanying-guests .accompany-header {
  margin-top: var(--spacing-lg);
}
.housekeeping-details-accompanying-guests .accompany-guest-icon {
  margin-right: var(--spacing-sm);
}

.accordion-accompanying-guests .ac-accordion-item:first-child {
  border-top: 0;
}
.accordion-accompanying-guests .ac-accordion-item:last-child {
  padding-bottom: 0;
}
