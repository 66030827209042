.maintenance-form-validation-modal .maintenance-form-validation-modal-conflict {
  padding-bottom: var(--spacing-md);
  padding-top: var(--spacing-md);
}
.maintenance-form-validation-modal .maintenance-form-validation-modal-conflict + .maintenance-form-validation-modal-conflict {
  border-top: 1px solid var(--color-gray200);
}
.maintenance-form-validation-modal .maintenance-form-validation-modal-conflict:last-child {
  padding-bottom: 0;
}
