.housekeeping-dashboard-room {
  overflow: hidden;
  transition: background-color 0.2s ease-in-out;
}
.housekeeping-dashboard-room.housekeeping-dashboard-room-selected {
  background-color: var(--color-primary-light);
}
.housekeeping-dashboard-room .housekeeping-dashboard-room-link {
  display: flex;
}
.housekeeping-dashboard-room .housekeeping-dashboard-room-indicator {
  width: 4px;
}
.housekeeping-dashboard-room .housekeeping-dashboard-room-content {
  flex: 1 1 100%;
  padding: var(--spacing-sm);
  overflow: hidden;
}
.housekeeping-dashboard-room .housekeeping-dashboard-room-extras {
  gap: var(--spacing-md);
}
.housekeeping-dashboard-room .housekeeping-dashboard-room-extras-with-checkbox {
  padding-left: 35px;
}
.housekeeping-dashboard-room .housekeeping-dashboard-room-checkbox-container {
  flex: 0 0 35px;
}
.housekeeping-dashboard-room .housekeeping-dashboard-room-number {
  flex: 1 1 100%;
  min-width: 0;
}
.housekeeping-dashboard-room .housekeeping-dashboard-room-avatar {
  flex: 0 0 55px;
}
.housekeeping-dashboard-room .housekeeping-dashboard-room-reservation {
  flex: 0 0 45px;
}
.housekeeping-dashboard-room .housekeeping-dashboard-room-housekeeping {
  flex: 0 0 45px;
}
.housekeeping-dashboard-room .housekeeping-dashboard-room-badge {
  flex: 0 0 55px;
}
