.attendant-room-item-header-room-number {
  cursor: pointer;
  flex-grow: 1;
}

.attendant-room-item-header.is-alert-bar {
  background-color: #fcf6e9;
  margin-top: calc(var(--spacing-md) * -1);
  margin-left: calc(var(--spacing-sm) * -1);
  margin-right: calc(var(--spacing-sm) * -1);
  border-bottom: 1px solid #eaa630;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.attendant-room-item-header.is-alert-bar .attendant-room-item-header-room-number-wrapper {
  flex: 1 1 100%;
  align-items: center;
}
.attendant-room-item-header.is-alert-bar .ready-by-time-room-dashboard {
  flex: 1 1 100%;
}

.attendant-room-item-modal .attendant-room-item-modal-body {
  padding: var(--spacing-lg);
}
