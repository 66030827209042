.avatar-modal .avatar-image {
  width: 100%;
  border-radius: 50%;
}

.ac-avatar-indicator-wrapper {
  position: absolute;
  top: calc(var(--spacing-lg) * -1);
  right: calc(var(--spacing-sm) * -1);
  pointer-events: none;
}
