.search-field .clear-button {
  width: 45px;
  height: 45px;
  position: absolute;
  top: 18px;
  right: 0;
}
.search-field .selected-option .ac-icon-button {
  display: none;
}
