html {
  --z-index-menu-container: 750;
  --menu-transition-duration: 300ms;
  --button-full-width: calc(100% - 34px);
  --selection-mode-border-width: 6px;
  --side-panel-transtion-duration: 1000ms;
}

body {
  /* TODO remove when mobile components will be removed */
  font-size: unset !important; // stylelint-disable-line declaration-no-important
}

.link {
  color: var(--color-primary-dark);
  text-decoration: underline;
}
/* stylelint-disable property-no-vendor-prefix */
.ac-field-datetimepicker .ac-field-datetime {
  -webkit-appearance: none;
  -moz-appearance: none;
}
.ac-header-center {
  overflow: hidden;
}
.ac-padding-sm {
  padding: var(--spacing-sm);
}
.full-width {
  width: 100%;
}
.position-relative {
  position: relative;
}
.menu-border-top {
  border-top: 1px solid rgba(var(--color-gray-3-rgb), 0.3);
}

.housekeeping-mobile-module .bordered-badge {
  border: solid 1px var(--color-gray300);
}

.scroll-support {
  -webkit-overflow-scrolling: touch;
}
