.overflow-accordion {
  width: 100%;
  overflow: hidden;
  border-top: 1px solid var(--color-gray200);
  padding: var(--spacing-md) 0;
}
.overflow-accordion .overflow-accordion-header {
  cursor: pointer;
}
.overflow-accordion .overflow-accordion-header-icon {
  margin-left: 20px;
}
.overflow-accordion .overflow-accordion-content-text {
  font-size: 13px;
  line-height: 20px;
  margin-top: 0.5em;
}
.overflow-accordion .overflow-accordion-content {
  max-height: 2em;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}
.overflow-accordion .overflow-accordion-expanded .overflow-accordion-content {
  max-height: 60em;
  transition: max-height 0.2s ease-in;
}
.overflow-accordion .overflow-accordion-collapsed .overflow-accordion-content-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.overflow-accordion .overflow-accordion-content-text-nowrap {
  white-space: nowrap;
}
