.badge-with-count {
  max-width: 100%;
}
.badge-with-count .ac-badge .ac-badge-tile {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.badge-with-count .badge-with-count-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-sm);
  border-width: 1px;
  border-style: solid;
  border-left: 0;
  height: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
