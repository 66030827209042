html {
  --blue-stripe-width: 48px;
}

.menu-container {
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 900;

  &:not(.menu-container--forced) {
    transition: transform var(--menu-transition-duration) ease-in-out;
  }

  &--opened {
    transform: translate(100%, 0);
  }
}

.app-menu {
  background: var(--color-primary-dark);
  display: flex;
  flex-direction: column;

  .top-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    border-right: 1px solid var(--color-gray300);

    div:last-of-type {
      flex: 1;
    }
    .active-module-container {
      display: flex;

      .module-icon-wrapper {
        background: var(--color-primary-dark);
        width: var(--blue-stripe-width);
        height: 100%;
      }
      .active-module-content-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        background: var(--color-white);
      }
      .module-item {
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &.active::before {
          content: '';
          display: block;
          width: 4px;
          height: 100%;
          background: var(--color-white);
          position: absolute;
          left: 0;
        }
      }
      .active-module-title {
        height: 32px;
        padding: 5px 8px;
        color: var(--color-black);
        background: var(--color-gray100);
        border-bottom: 1px solid var(--color-gray300);
        font-weight: var(--font-weight-semibold);
      }
      .active-module-content {
        width: 100%;
        overflow: hidden auto;
        position: relative;

        .link-item {
          min-height: 42px;
          border-bottom: 1px solid var(--color-gray300);

          .link {
            cursor: pointer;
            padding: 11px 8px;
            color: var(--color-black);
            font-size: var(--ac-font-size-main1);
            text-decoration: none;
            display: block;
          }
          &.active {
            background: var(--color-primary-dark);
            border-bottom: 0;
            .link {
              color: var(--color-white);
            }
          }
        }
      }
    }
  }
  .bottom-wrapper {
    border-right: 1px solid var(--color-gray300);

    .item-content {
      flex-grow: 1;
      border-top: 1px solid var(--color-gray300);
      background: var(--color-white);
      width: calc(100% - 48px);
      overflow: hidden;
    }
    .item-icon {
      color: var(--color-white);
      width: var(--blue-stripe-width);
      background: var(--color-primary-dark);
      display: flex;
      justify-content: center;
    }
    .item-property {
      height: 51px;
      display: flex;

      .item-icon {
        align-items: center;
      }
    }
    .item-user-data {
      height: 77px;
      display: flex;

      .item-content {
        display: flex;
        align-items: flex-start;

        .user-data {
          width: calc(100% - 50px);
          padding: 5px 8px;
          min-width: 0;
        }
        .username {
          font-size: var(--ac-font-size-main1);
        }
        .email {
          font-size: var(--ac-font-size-main2);
          color: var(--color-gray700);
        }
        .role {
          font-size: var(--ac-font-size-main2);
          color: var(--color-primary-dark);
          font-weight: var(--font-weight-semibold);
        }
        .username,
        .email,
        .role {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .item-logout {
        margin-top: 15px;
        width: 50px;
        min-width: 0;
      }
    }
  }
}
