.notes-accordion {
  border-top: 1px solid var(--color-gray200);
  padding-top: var(--spacing-lg);
}
.notes-accordion:first-child {
  border-top: 0;
  padding-top: 0;
}
.notes-accordion .notes-accordion-item:first-child {
  border-top: 0;
  padding-top: 0;
}
.notes-accordion .notes-accordion-item .notes-accordion-item-content {
  font-size: var(--ac-font-size-main2);
}
