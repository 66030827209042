.inner-accordion .ac-accordion-item {
  border: 0;
  border-bottom: 1px solid var(--color-gray200);
  padding: var(--spacing-sm) 0;
}
.inner-accordion .ac-accordion-content-text {
  margin-top: 0;
}
.inner-accordion .ac-accordion-header .ac-flex {
  align-items: center;
}
.inner-accordion .ac-accordion-header .ac-flex .header-content .ac-flex {
  align-items: flex-start;
}
