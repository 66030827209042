.animation-route-swipe {
  &.exit {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: var(--vw100);
    height: var(--vh100);
    z-index: 1;
  }
  &.enter {
    pointer-events: none;
    transform: translateX(var(--animation-route-swipe-position));
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background-color: var(--color-white);
      pointer-events: none;
      touch-action: none;
      transition: background-color 0.15s ease-in-out;
    }
  }
  &.enter.enter-active {
    transform: translateX(0);
    transition: transform 0.2s ease-out;
    &::after {
      background-color: transparent;
    }
  }
}
