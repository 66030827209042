.accordion .ac-accordion-item {
  border-top: 0;
  padding: 0;
}
.accordion .ac-accordion-content-text {
  margin-top: 0;
}
.accordion .ac-accordion-header .ac-flex {
  align-items: center;
}
.accordion .ac-accordion-header .header-content .ac-flex {
  align-items: flex-start;
}
.accordion .ac-accordion-header .header-content .ac-form-element-value .ac-flex {
  align-items: center;
}
