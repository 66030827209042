.housekeeping-details-shared-guests .shared-guest-icon {
  margin-right: var(--spacing-sm);
}
.housekeeping-details-shared-guests .shared-guest-title {
  line-height: 1;
}
.housekeeping-details-shared-guests .shared-guest-name {
  margin-left: 20px;
}
.housekeeping-details-shared-guests .accordion-shared-guests > .ac-accordion-item {
  padding: 0;
  border: 0;
}
