.filters-wrapper {
  padding: var(--spacing-sm);
}
.filters-wrapper .ac-body-main {
  padding: 0;
}

.date-time-filters-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--spacing-sm);
}
