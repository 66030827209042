.task-modal .ac-modal-header.task-modal-header {
  background-color: var(--color-primary-dark);
  color: var(--color-white);
}
.task-modal .ac-modal-header.task-modal-header .ac-modal-header-close-button .ac-icon-cancel {
  color: var(--color-white);
}
.task-modal .ac-modal-footer.task-modal-footer {
  border-top: 1px solid var(--color-gray300);
  background: var(--color-gray50);
  padding: var(--spacing-md) 0 0 0;
}
.task-modal .ac-modal-footer.task-modal-footer .footer-buttons {
  padding: 0 var(--spacing-lg);
}
.task-modal .modal-body {
  padding: 0 var(--spacing-lg);
}
