.attendant-assignments-summary .sheets-summary {
  display: grid;
  grid-auto-rows: min-content auto;
  height: 100%;
}
.attendant-assignments-summary .attendant-assignments-summary {
  border-radius: 8px;
  background-color: var(--color-gray50);
}
.attendant-assignments-summary .sheets-summary-accordion + .sheets-summary-accordion {
  margin-top: var(--spacing-sm);
}
.attendant-assignments-summary .sticky-button-test {
  position: sticky;
  bottom: 20px;
}
.attendant-assignments-summary .sheet-select {
  padding: var(--spacing-sm);
}
