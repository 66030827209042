.task-header {
  box-shadow: inset 0 -1px 0 var(--color-gray300);
  padding-bottom: var(--spacing-sm);
}
.task-header .task-id-and-name {
  max-height: 40px;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
}

.task-name {
  word-wrap: break-word;
}
