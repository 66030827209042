.attendant-room-list-body {
  -webkit-overflow-scrolling: touch;
}

.attendant-room-list-header {
  box-shadow: none;
}

.attendant-room-list-body {
  background-color: var(--color-gray50);
}
.attendant-room-list-body .ac-body-main {
  padding: 0 !important;
}
.attendant-room-list-body .attendant-room-list-navigation {
  position: relative;
}
.attendant-room-list-body .attendant-room-list-navigation .ac-anchor-navigation-item-list {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
}
.attendant-room-list-body .attendant-room-list-navigation .ac-anchor-content-list {
  height: calc(var(--vh100) - var(--header-height-mobile) - var(--anchor-list-nav-height));
}
.attendant-room-list-body .attendant-room-list-navigation .attendant-room-list-section-list {
  padding: 0 var(--spacing-sm);
}
.attendant-room-list-body .attendant-room-list-navigation .attendant-room-list-section-list .attendant-room-list-section-header {
  padding: var(--spacing-md) var(--spacing-s);
}
.attendant-room-list-body .attendant-room-list-navigation .room-queue-alert {
  position: sticky;
  top: 0;
}
