.quick-filter {
  max-width: 100%;
  width: 100%;
}
.quick-filter .quick-filter-scroll {
  overflow: scroll hidden;
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  width: 100%;
}
.quick-filter .quick-filter-scroll::-webkit-scrollbar {
  display: none;
}
