.maintenance-attachments .maintenance-attachments-item {
  padding: var(--spacing-sm) 0;
}
.maintenance-attachments .maintenance-attachments-button {
  flex: 1 1 100%;
}
.maintenance-attachments .maintenance-attachments-icon {
  flex: 0 1 auto;
}
.maintenance-attachments .maintenance-attachments-item-name {
  flex: 1 1 0;
}
.maintenance-attachments .maintenance-attachments-item + .maintenance-attachments-item {
  border-top: 1px solid var(--color-gray200);
}
.maintenance-attachments .maintenance-attachments-item-description {
  display: block;
}
